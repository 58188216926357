<template>
  <div id='user'>
<!--    <p class="bread-crumbs">{{route.name}} / {{subRouteName}}</p>-->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'user',
    data () {
      return {
        route: null,
        subRouteName: null
      }
    },
    created () {
      // 初次渲染面包屑\
      this.renderNav()
      // 监听路由变化，修改顶部面包屑文字
      this.$watch('$route', to => this.subRouteName = to.name)
    },
    methods: {
      renderNav () {
        const route = this.$store.state.user.routes;
        this.route = route.find(item => item.path === '/admin').children.find(item => item.path === 'setting')
        const subPath = this.$route.path.split('/')[3]
        this.subRouteName = this.route.children.find(item => item.path === subPath).name
      }
    }
  }
</script>

<style lang='less' scoped>
  #user {
    background: #fff;
    padding: 16px;
    .bread-crumbs {
      font-size: 12px;
      color: #666;
      padding: 8px 10px;
      background: #F0F0F0;
      margin-bottom: 10px;
    }
  }
</style>
